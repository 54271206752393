$form-group-margin-bottom: 0;
$input-font-size: 16px;

html {
  font-size: 15px;
}

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
// @import '../../node_modules/bootstrap/scss/images';
// // @import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
// @import '../../node_modules/bootstrap/scss/transitions';
// // @import '../../node_modules/bootstrap/scss/dropdown';
// @import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
// // @import '../../node_modules/bootstrap/scss/custom-forms';
// @import '../../node_modules/bootstrap/scss/nav';
// // @import '../../node_modules/bootstrap/scss/navbar';
// // @import '../../node_modules/bootstrap/scss/card';
// // @import '../../node_modules/bootstrap/scss/breadcrumb';
// // @import '../../node_modules/bootstrap/scss/pagination';
// // @import '../../node_modules/bootstrap/scss/badge';
// // @import '../../node_modules/bootstrap/scss/jumbotron';
@import '../../node_modules/bootstrap/scss/alert';
// // @import '../../node_modules/bootstrap/scss/progress';
// // @import '../../node_modules/bootstrap/scss/media';
// // @import '../../node_modules/bootstrap/scss/list-group';
// @import '../../node_modules/bootstrap/scss/close';
// // @import '../../node_modules/bootstrap/scss/modal';
// // @import '../../node_modules/bootstrap/scss/tooltip';
// @import '../../node_modules/bootstrap/scss/popover';
// // @import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/utilities';
// @import '../../node_modules/bootstrap/scss/print';
@import './_validation';
@import '../../node_modules/@fortawesome/fontawesome/styles.css';
@import '../../node_modules/rc-slider/assets/index.css';
// @import '../../node_modules/rc-tooltip/assets/bootstrap.css';
@import '../../node_modules/react-day-picker/lib/style.css';

.fastest {
  animation-duration: 0.25s;
}

#root > form > .content {
  overflow: hidden;
}

strong {
  font-weight: bold;
}

.form-check {
  padding-left: 0;
  margin-bottom: 0.25em;
  display: flex;
  align-items: baseline;
}
.form-check-input-wrap {
  margin: 0 0.5em 0 0;
  flex: 0 0 auto;
}
.form-check-input {
  position: static;
  margin: 0;
  height: 0.9em;
}
.form-check-label {
  flex: 1 1 auto;
}

.form-group > label {
  font-weight: 600;
}

label {
  margin-bottom: 0.4rem;
}
@media (max-width: 768px) {
  .step-view:not(.embedded),
  .mobile-touch-helpers {
    .checkbox-group,
    .radio-group {
      border: 1px solid #dee2e6;
      border-radius: 3px;
    }
    .form-check {
      margin: 0;
      border-top: 1px solid #dee2e6;
      padding: 0 0.6em;
      .form-check-label {
        padding: 0.6em 0;
      }
      &:first-child {
        border-top: 0;
      }
    }

    label + .form-text {
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.btn-secondary {
  color: #333;
  background-color: #f3f3f3;
  border-color: #c3c3c3;
}



@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}