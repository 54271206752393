// todo: remove pathway.scss and simply include it on the editor environment in assetloader
@import './pathway.scss';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/popover';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/utilities';
@import './_animations';

#root {
  min-height: 100vh;
}

// Ace editor
.ace_editor {
  min-height: 200px;
}
.ace_editor.ace-tm {
  background-color: #fcfcfd;
}
